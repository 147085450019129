import theme from "common/theme";

const thursdayScheduleStyles = {
  section: {
    backgroundColor: theme.palette.background.lightGray,
    padding: "2.5rem",
  },
};

export default thursdayScheduleStyles;
